<template>
  <div>
      <global-table :addButton="'Add Menu'" :fields="fields" :table-data="getData" :total-rows="totalRows" :loading="isLoading" :redirect-detail="false" @addForm="addForm" @editForm="editForm" :showAddButton="admin == 1 ? true : false"></global-table>
    
    <b-modal ref="modalForm" id="addModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="lg">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">
          {{ isEdit == true ? "Edit" : "Add" }} Menu
        </h3>
      </div>

      <validation-observer v-if="!isLoading" ref="menu" tag="form" @submit.prevent="submitAdd">
        <b-form>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Title">
                <validation-provider #default="{ errors }" name="Title" rules="required">
                  <b-form-input id="Title" placeholder="Title" v-model="formData.title" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Route">
                <validation-provider #default="{ errors }" name="Route">
                  <b-form-input id="Route" placeholder="Route" v-model="formData.route" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Icon">
                <validation-provider #default="{ errors }" name="Icon" rules="required">
                  <b-form-input id="Icon" placeholder="Icon" v-model="formData.icon" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Children">
                <validation-provider #default="{ errors }" name="Children">
                  <model-select :options="dropdownChildren" v-model="formData.children" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Children Lv2">
                <validation-provider #default="{ errors }" name="Children Lv2">
                  <model-select :options="dropdownChildren_lv2" v-model="formData.children_lv2" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Children Lv3">
                <validation-provider #default="{ errors }" name="Children Lv3">
                  <model-select :options="dropdownChildren_lv3" v-model="formData.children_lv3" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
            </b-col>
            <b-col sm="6">
              <b-form-group label="Ordering">
                <validation-provider #default="{ errors }" name="Ordering" rules="required">
                  <b-form-input id="Ordering" placeholder="Ordering" v-model="formData.ordering" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <loading v-if="isLoading"></loading>

      <div slot="modal-footer" class="float-right">
        <b-button type="button" size="sm" variant="danger" @click="cancelAdd"><i class="fa fa-undo"></i> Back</b-button>
        &nbsp;
        <b-button v-if="admin == 1" type="button" size="sm" variant="primary" @click="submitAdd"><i class="fa fa-save"></i> Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import interfaces from "@/templates/_interfaces.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Loading from "@core/loading/Loading.vue";
import GlobalTable from "@/templates/GlobalTableNew.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Loading,
    GlobalTable
  },
  data() {
    return {
      admin : localStorage.getItem("admin"),
      isEdit: false,
      totalRows: 1,
      isLoading: false,
      data: [],
      dropdownChildren: [],
      dropdownChildren_lv2: [],
      dropdownChildren_lv3: [],
      formData: interfaces.master_menu,
      fields: [
        { key: "id", label: "ID" },
        { key: "title", label: "Title" },
        { key: "route", label: "Route" },
        { key: "icon", label: "Icon" },
        { key: "children_name", label: "Children lv1" },
        { key: "children_lv2_name", label: "Children lv2" },
        { key: "children_lv3_name", label: "Children lv3" },
        { key: "ordering", label: "Ordering" },
        { key: "action", label: "Action", class: "valign-top text-center width-80"},
      ],
      editorOption: {},
    };
  },
  methods: {
    init: function () {
      this.isEdit = false;
      // this.isLoading = true;
      
      // this.getData();
      this.getDropdown();
    },
    // getData: function () {
    //   axios.get("master/menu/list").then((response) => {
    //     this.data = response.data.data;
    //   }).catch((error) => {
        
    //   }).finally(() => {
    //     this.isLoading = false;
    //   });
    // },
    getData: function (ctx) {
      let response = axios.post("master/menu/list", ctx);
      return response.then((response) => {
        const items = response.data.data;
        this.totalRows = response.data.total;
        return items;
      }).catch((error) => {
        return [];
      });
    },
    cancelAdd: function () {
      this.resetForm();
      this.$refs.modalForm.hide();
    },
    addForm: function () {
      this.resetForm();
    },
    editForm: function (a) {
      this.isEdit = true;
      this.formData = a;
      this.$refs.modalForm.show();
    },
    resetForm: function () {
      this.isEdit = false;
      for (var i in interfaces.master_menu) {
        interfaces.master_menu[i] = null;
      }
      this.formData = interfaces.master_menu;
    },
    getDropdown: function () {
      
      axios.get("master/menu/dropdown").then((response) => {
        this.dropdownChildren = response.data.children;
        this.dropdownChildren_lv2 = response.data.children_lv2;
        this.dropdownChildren_lv3 = response.data.children_lv3;
      }).catch((error) => {
        
      }).finally(() => {
        this.isLoading = false;
      });
    },
    submitAdd: function () {
      this.$refs.menu.validate().then((success) => {
        if (success) {
          if (this.isEdit == true) {
            if (confirm("Are you sure want to Update this data ?")) {
              this.isLoading = true;
              axios
                .put("master/menu/edit", this.formData)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Form Successful",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                })
                .catch((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Form Failed",
                      icon: "EditIcon",
                      variant: "danger",
                      text: error.message,
                    },
                  });
                })
                .finally(() => {
                  this.isLoading = false;
                  this.$refs.modalForm.hide();
                  this.resetForm();
                  this.init();
                });
            }
          } else {
            if (confirm("Are you sure want to Add this data ?")) {
              this.isLoading = true;
              axios
                .post("master/menu/add", this.formData)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add successful",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                })
                .catch((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add Failed",
                      icon: "EditIcon",
                      variant: "danger",
                      text: error.message,
                    },
                  });
                })
                .finally(() => {
                  this.isLoading = false;
                  this.$refs.modalForm.hide();
                  this.resetForm();
                  this.init();
                });
            }
          }
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
td.valign-top {
  vertical-align: top;
}
</style>